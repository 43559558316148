import React, { useState, FC, Dispatch, SetStateAction } from 'react'
import { Button, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { SurveyState } from './App'
import { FlowAnswer, flowQuestions } from './data/FlowQuestionsData'

export const FlowQuestionnaire: FC<{
  state: SurveyState
  setState: Dispatch<SetStateAction<SurveyState>>
  advance: () => void
}> = ({ state, setState, advance }) => {
  const [unfilledVariables, setUnfilledVariables] = useState(true)
  const [lieItemValue, setLieItemValue] = useState<string | null>(null)

  function saveAnswer(newValue: number, field: keyof FlowAnswer) {
    const newState: SurveyState = {
      ...state,
      flow: { ...state.flow, [field]: newValue },
    }
    setState(newState)
    setUnfilledVariables(
      Object.values(newState.flow).includes(null) || lieItemValue === null,
    )
  }

  function RadioButtons(type: keyof FlowAnswer) {
    return (
      <RadioGroup
        row
        aria-label="anonymous"
        name="anonymous"
        value={state.flow[type]}
        onChange={(event) => saveAnswer(parseInt(event.target.value), type)}
      >
        <FormControlLabel
          value={-3}
          control={<Radio />}
          labelPlacement="bottom"
          label="Strongly disagree"
        />
        <FormControlLabel
          value={-2}
          control={<Radio />}
          labelPlacement="bottom"
          label=""
        />
        <FormControlLabel
          value={-1}
          control={<Radio />}
          labelPlacement="bottom"
          label=""
        />
        <FormControlLabel
          value={0}
          control={<Radio />}
          labelPlacement="bottom"
          label="Undecided"
        />
        <FormControlLabel
          value={1}
          control={<Radio />}
          labelPlacement="bottom"
          label=""
        />
        <FormControlLabel
          value={2}
          control={<Radio />}
          labelPlacement="bottom"
          label=""
        />
        <FormControlLabel
          value={3}
          control={<Radio />}
          labelPlacement="bottom"
          label="Strongly agree"
        />
      </RadioGroup>
    )
  }

  function lieItem() {
    return (
      <div className="lieItem">
        <p style={{ marginTop: '40px' }}></p>
        <b>
          This is a reading test item, please mark the ‘Strongly agree’ answer
          option.
        </b>
        <RadioGroup
          row
          aria-label="lieItemFlow"
          name="lieItemFlow"
          value={lieItemValue}
          onChange={(event) => {
            setLieItemValue(event.target.value)
            setState({
              ...state,
              lieItemFlow: event.target.value === 'correct',
            })
            setUnfilledVariables(
              Object.values(state.flow).includes(null) ||
                event.target.value === null,
            )
          }}
        >
          <FormControlLabel
            value="wrong0"
            control={<Radio />}
            labelPlacement="bottom"
            label="Strongly disagree"
          />
          <FormControlLabel
            value="wrong1"
            control={<Radio />}
            labelPlacement="bottom"
            label=""
          />
          <FormControlLabel
            value="wrong2"
            control={<Radio />}
            labelPlacement="bottom"
            label=""
          />
          <FormControlLabel
            value="wrong3"
            control={<Radio />}
            labelPlacement="bottom"
            label="Undecided"
          />
          <FormControlLabel
            value="wrong4"
            control={<Radio />}
            labelPlacement="bottom"
            label=""
          />
          <FormControlLabel
            value="wrong5"
            control={<Radio />}
            labelPlacement="bottom"
            label=""
          />
          <FormControlLabel
            value="correct"
            control={<Radio />}
            labelPlacement="bottom"
            label="Strongly agree"
          />
        </RadioGroup>
      </div>
    )
  }

  return (
    <div className={'formContainer7answers'}>
      <h1>Survey - Game Questionnaire</h1>

      <p>
        Please recall your experience of the game you just completed. There are
        no right or wrong answers, please reply intuitively and honestly to help
        this research.
      </p>

      {Object.keys(flowQuestions).map((key: string, index: number) => (
        <div className="flow" key={index}>
          <p style={{ marginTop: '40px' }}></p>
          <b>{' ' + flowQuestions[key as keyof FlowAnswer]}</b>
          {RadioButtons(key as keyof FlowAnswer)}
        </div>
      ))}

      {lieItem()}

      {unfilledVariables && (
        <p className="buttonHelperText">
          All fields must be filled before proceeding
        </p>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={advance}
        className="submitButton"
        disabled={unfilledVariables}
      >
        Next
      </Button>
    </div>
  )
}
