import React, { useState, useEffect, FC, Dispatch, SetStateAction } from 'react'
import { Button, FormControlLabel, Checkbox, TextField } from '@mui/material'
import {
  creativeAchievementsData,
  categoryOrder,
  shortCategoryNames,
  CategoryNames,
} from './data/CreativeAchievementsData'
import { SurveyState } from './App'

export const CreativeAchievements: FC<{
  state: SurveyState
  setState: Dispatch<SetStateAction<SurveyState>>
  advance: () => void
  page: number
}> = ({ state, setState, advance, page }) => {
  const [unfilledVariables, setUnfilledVariables] = useState(false)
  const firstCAPage = 6

  function checkUnfilledVariables(category: string, newState: SurveyState) {
    const setItems = newState.CA[category].filter((e) => e !== false)
    setUnfilledVariables(setItems.length === 0)
  }

  useEffect(() => {
    const category = categoryOrder[page - firstCAPage]
    const shortName: string =
      shortCategoryNames[category as keyof CategoryNames]

    checkUnfilledVariables(shortName, state)
  })

  function updateItemInCategory(
    category: string,
    index: number,
    value: boolean | number,
  ) {
    const shortName: string =
      shortCategoryNames[category as keyof CategoryNames]
    setState((s) => {
      const ca = Object.assign({}, s.CA)
      ca[shortName][index] = value

      return {
        ...s,
        CA: ca,
      }
    })
  }

  function RateAchievements() {
    const category = categoryOrder[page - firstCAPage]
    const activities = creativeAchievementsData[category]
    const shortName: string =
      shortCategoryNames[category as keyof CategoryNames]
    return (
      <div>
        <h3>{category.charAt(0).toUpperCase() + category.slice(1)}</h3>
        <p>
          Check the box next to all sentences that apply to you.<br></br> Next
          to sentences with an asterisk (*), write the number of times this
          applies to you.
        </p>
        {activities.map((activity: string, index: number) => (
          <div key={activity}>
            {activity.startsWith('*') ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <TextField
                  type="number"
                  inputProps={{ style: { padding: '8px' } }}
                  style={{ maxWidth: '60px', marginRight: '16px' }}
                  value={
                    state.CA[shortName][index] === false
                      ? 0
                      : state.CA[shortName][index]
                  }
                  onChange={(event) => {
                    if (parseInt(event.target.value) < 0) return
                    updateItemInCategory(
                      category,
                      index,
                      parseInt(event.target.value),
                    )
                  }}
                />
                <p style={{ marginTop: '10px' }}>{activity}</p>
              </div>
            ) : (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.CA[shortName][index] ?? false}
                    name="0"
                    onChange={(_e, checked) =>
                      updateItemInCategory(category, index, checked)
                    }
                  />
                }
                label={activity}
              />
            )}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={'formContainer'}>
      <h1>Survey - Questionnaire</h1>
      {RateAchievements()}

      {unfilledVariables && (
        <p className="buttonHelperText">
          You must select at least one option before proceeding
        </p>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="submitButton"
        disabled={unfilledVariables}
        onClick={advance}
      >
        Next
      </Button>
    </div>
  )
}
