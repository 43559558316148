import { SurveyState } from './App'
import { useEffect, FC, Dispatch, SetStateAction } from 'react'
import { Button } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import React from 'react'

export const STUDY_REWARD = 5
export const STUDY_CURRENCY = '$'
export const isInTestMode =
  window.location.pathname === '/test' || window.location.pathname === '/test/'

export const Welcome: FC<{
  state: SurveyState
  setState: Dispatch<SetStateAction<SurveyState>>
  advance: () => void
}> = ({ state, setState, advance }) => {
  useEffect(() => {
    if (state.id == null) setState((state) => ({ ...state, id: uuidv4() }))
  }, [setState, state])

  return (
    <div className={'formContainer'}>
      <h1>Welcome to our Survey</h1>
      <p>
        This survey consists of several small games and questionnaires. All in
        all it should take you between 30 and 35 minutes to complete. Look at
        the table below for time estimates for each part of the study.
      </p>

      <table className={'center'}>
        <tbody>
          <tr>
            <th>Game 1</th>
            <th>about 6 minutes</th>
          </tr>
          <tr>
            <th>Association Games</th>
            <th>about 7 minutes</th>
          </tr>
          <tr>
            <th>Questionnaires</th>
            <th>about 17 minutes</th>
          </tr>
        </tbody>
      </table>

      <p>
        All data collected during this survey will be stored in an anonymized
        format on our server. The data is used for research purposes and
        scientific publications.
      </p>
      <p>
        We recommend completing this survey on a laptop or desktop computer,
        using a mouse. Playing the game on a tablet will not work, and you will
        not receive the reward at the end. The game includes sound, please turn
        on your sound.
      </p>
      <p>
        For completing this study you receive a payment of {STUDY_REWARD}
        {STUDY_CURRENCY}.
      </p>
      <p>
        To obtain the payment, you need to complete the entire study and submit
        valid data (do not leave the game open without playing).
      </p>

      <p>Have Fun!</p>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="startButton"
        onClick={advance}
      >
        Start
      </Button>
    </div>
  )
}
