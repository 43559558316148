import React, { useState, FC, Dispatch, SetStateAction, useEffect } from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'
import './App.css'
import { SurveyState, numberOfPages } from './App'

export const DemographicsSurvey: FC<{
  state: SurveyState
  setState: Dispatch<SetStateAction<SurveyState>>
  advance: () => void
  page: number
}> = ({ state, setState, advance, page }) => {
  const [unfilledVariables, setUnfilledVariables] = useState(true)
  const [lieItemValue, setLieItemValue] = useState<string | null>(null)

  function checkUnfilledVariables(newState: SurveyState): boolean {
    return (
      newState.age === null ||
      newState.degree === null ||
      newState.gender === null ||
      newState.englishLevel === null ||
      newState.continent === null
    )
  }

  useEffect(() => {
    if (state.timeMarks.afterCAQ !== null) return
    const newState = {
      ...state,
      timeMarks: {
        ...state.timeMarks,
        afterCAQ: Date.now(),
      },
    }
    setState(newState)
    window.localStorage.setItem('surveyState', JSON.stringify(newState))
  }, [state.timeMarks])

  const updateState = (newState: SurveyState) => {
    setState(newState)
    setUnfilledVariables(
      checkUnfilledVariables(newState) || lieItemValue === null,
    )
  }

  function selectGender() {
    return (
      <FormControl component="fieldset" className="formControl demographics">
        <FormLabel component="legend">What is your gender?</FormLabel>
        <RadioGroup
          aria-label="gender"
          name="gender"
          value={state.gender}
          onChange={(event) =>
            updateState({ ...state, gender: parseInt(event.target.value) })
          }
        >
          <FormControlLabel value={0} control={<Radio />} label="Male" />
          <FormControlLabel value={1} control={<Radio />} label="Female" />
          <FormControlLabel value={2} control={<Radio />} label="Other" />
        </RadioGroup>
      </FormControl>
    )
  }

  function selectSchooling() {
    return (
      <FormControl component="fieldset" className="formControl demographics">
        <FormLabel component="legend">
          What is the highest degree or level of school you have completed?
          <br></br>
          <em>If currently enrolled, highest degree received.</em>
        </FormLabel>
        <RadioGroup
          name="degree"
          value={state.degree}
          onChange={(event) =>
            updateState({ ...state, degree: parseInt(event.target.value) })
          }
        >
          <FormControlLabel
            value={0}
            control={<Radio />}
            label="No schooling completed"
          />
          <FormControlLabel
            value={1}
            control={<Radio />}
            label="School up to 8th grade"
          />
          <FormControlLabel
            value={2}
            control={<Radio />}
            label="School up to 10th grade"
          />
          <FormControlLabel value={3} control={<Radio />} label="High school" />
          <FormControlLabel value={4} control={<Radio />} label="College" />
          <FormControlLabel
            value={5}
            control={<Radio />}
            label="Trade / technical / vocational training"
          />
          <FormControlLabel
            value={6}
            control={<Radio />}
            label="Bachelor's degree"
          />
          <FormControlLabel
            value={7}
            control={<Radio />}
            label="Master's degree"
          />
          <FormControlLabel
            value={8}
            control={<Radio />}
            label="Doctorate degree"
          />
        </RadioGroup>
      </FormControl>
    )
  }

  function selectEnglishLevel() {
    return (
      <FormControl component="fieldset" className="formControl demographics">
        <FormLabel component="legend">
          What is your English language proficiency?
        </FormLabel>
        <RadioGroup
          name="englishLevel"
          value={state.englishLevel}
          onChange={(event) =>
            updateState({
              ...state,
              englishLevel: parseInt(event.target.value),
            })
          }
        >
          <FormControlLabel
            value={0}
            control={<Radio />}
            label="Basic – I can communicate on simple topics or know some phrases in this language."
          />
          <FormControlLabel
            value={1}
            control={<Radio />}
            label="Conversational – I can communicate on everyday topics with minor grammar or vocabulary mistakes"
          />
          <FormControlLabel
            value={2}
            control={<Radio />}
            label="Fluent – I have the ability to express any idea without hesitation, with good vocabulary and grammar; people understand me easily. Both my spoken and written skills are good."
          />
          <FormControlLabel
            value={3}
            control={<Radio />}
            label="Proficient – This language is my mother tongue, or I speak and write this language like a native speaker."
          />
        </RadioGroup>
      </FormControl>
    )
  }

  function selectContinent() {
    return (
      <FormControl component="fieldset" className="formControl demographics">
        <FormLabel component="legend">Where do you live?</FormLabel>
        <RadioGroup
          name="continent"
          value={state.continent}
          onChange={(event) =>
            updateState({
              ...state,
              continent: parseInt(event.target.value),
            })
          }
        >
          <FormControlLabel value={0} control={<Radio />} label="Africa" />
          <FormControlLabel value={1} control={<Radio />} label="Antarctica" />
          <FormControlLabel value={2} control={<Radio />} label="Asia" />
          <FormControlLabel value={3} control={<Radio />} label="Europe" />
          <FormControlLabel
            value={4}
            control={<Radio />}
            label="North America"
          />
          <FormControlLabel value={5} control={<Radio />} label="Oceania" />
          <FormControlLabel
            value={6}
            control={<Radio />}
            label="South America"
          />
        </RadioGroup>
      </FormControl>
    )
  }

  return (
    <form className="main">
      <div className={'formContainer demographics'}>
        <h1>Survey - Demographics</h1>

        <TextField
          id="standard-basic"
          label="Age"
          style={{ maxWidth: '300px' }}
          onChange={(event) =>
            updateState({ ...state, age: parseInt(event.target.value) })
          }
          type="number"
          InputLabelProps={{ shrink: true }}
        />

        {selectGender()}
        {selectSchooling()}
        {selectEnglishLevel()}

        <FormControl
          component="fieldset"
          className={'formControl demographics lieItem'}
        >
          <FormLabel component="legend">
            This item checks language proficiency, please mark the second answer
            option from the left.
          </FormLabel>
          <RadioGroup
            row
            aria-label="lieItem_demographics"
            name="lieItem_demographics"
            value={lieItemValue}
            onChange={(event) => {
              setLieItemValue(event.target.value)
              setState({
                ...state,
                lieItemDemographics: event.target.value === 'correct',
              })
              setUnfilledVariables(
                checkUnfilledVariables(state) || event.target.value === null,
              )
            }}
          >
            <FormControlLabel value="wrong1" control={<Radio />} label="1" />
            <FormControlLabel value="correct" control={<Radio />} label="2" />
            <FormControlLabel value="wrong2" control={<Radio />} label="3" />
            <FormControlLabel value="wrong3" control={<Radio />} label="4" />
          </RadioGroup>
        </FormControl>

        {selectContinent()}

        <p
          style={{ visibility: unfilledVariables ? 'hidden' : 'visible' }}
          className="buttonPagesLeft"
        >
          Almost done, only {numberOfPages - page} page
          {numberOfPages - page != 1 ? 's' : ''} left.
        </p>
        {unfilledVariables && (
          <p className="buttonHelperText">
            All fields must be filled before proceeding
          </p>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={'submitButton formControl'}
          disabled={unfilledVariables}
          onClick={advance}
        >
          Next
        </Button>
      </div>
    </form>
  )
}
